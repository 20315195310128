export * from './types/HTMLAttributes.js';
const parseType = (value, old) => {
    if (value === undefined)
        return old;
    if (typeof old === 'string')
        return String(value);
    if (typeof old === 'number')
        return Number(value);
    if (typeof old === 'boolean') {
        if (typeof value === 'boolean')
            return value;
        return value === 'true' ? true : false;
    }
    throw new TypeError();
};
const baseStyle = new CSSStyleSheet();
baseStyle.replaceSync(/*css*/ `:host{ user-select: none;-webkit-user-select: none }`);
export const useElement = (options) => {
    const sheet = new CSSStyleSheet();
    sheet.replaceSync(options.style ?? '');
    const attrs = [];
    const upperAttrs = {};
    for (const key in options.props) {
        const value = key.toLowerCase();
        attrs.push(value);
        upperAttrs[value] = key;
    }
    const map = new Map();
    class Protoptype extends HTMLElement {
        static observedAttributes = attrs;
        static define(name) {
            customElements.define(name, this);
        }
        constructor() {
            super();
            const shadowRoot = this.attachShadow({ mode: 'open' });
            shadowRoot.adoptedStyleSheets = [baseStyle, sheet];
            shadowRoot.innerHTML = options.template ?? '';
            const props = { ...options.props };
            const setups = options.setup?.apply(this, [shadowRoot]);
            for (const key in options.props) {
                Object.defineProperty(this, key, {
                    get: () => props[key],
                    set: (v) => {
                        const value = parseType(v, options.props[key]);
                        if (value === props[key])
                            return;
                        if (options.syncProps === true || options.syncProps?.includes(key)) {
                            const lowerKey = key.toLowerCase();
                            const attrValue = this.getAttribute(lowerKey);
                            const valueStr = String(value);
                            if (value === options.props?.[key] && attrValue !== null) {
                                this.removeAttribute(lowerKey);
                                return;
                            }
                            if (value !== options.props?.[key] && attrValue !== valueStr) {
                                this.setAttribute(lowerKey, valueStr);
                                return;
                            }
                        }
                        props[key] = value;
                        setups?.watches?.[key]?.(value);
                    }
                });
            }
            for (const key in setups?.expose) {
                Object.defineProperty(this, key, { get: () => setups?.expose[key] });
            }
            map.set(this, setups);
        }
        connectedCallback() {
            map.get(this)?.mounted?.();
        }
        disconnectedCallback() {
            map.get(this)?.unmounted?.();
        }
        adoptedCallback() {
            map.get(this)?.adopted?.();
        }
        attributeChangedCallback(key, _, newValue) {
            this[upperAttrs[key]] = newValue ?? undefined;
        }
    }
    return Protoptype;
};
