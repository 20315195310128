import { useElement } from './core/element.js';
const name = 's-divider';
const props = {};
const style = /*css*/ `
:host{
  display: block;
  height: 1px;
  background: var(--s-color-outline-variant, #c7c5d0);
  margin: 0 16px;
}
`;
const template = /*html*/ `<slot></slot>`;
export class Divider extends useElement({ style, template, props }) {
}
Divider.define(name);
